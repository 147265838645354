import img from '../assets/product.png'
export const obj = [
    {  
    "id":1,
    "img":img,
    "product-details":{
        name:"Crossover",
        category:"Jersey and Shorts",
        price:"$39.00 AUD"
    },
    "qty":"5",
    "size":"XXXL"
    },
    {  
    "id":2,
    "img":img,
    "product-details":{
        name:"Crossover",
        category:"Jersey and Shorts",
        price:"$39.00 AUD"
    },
    "qty":"34",
    "size":"XXXL"
    },
    {  
    "id":2,
    "img":img,
    "product-details":{
        name:"Crossover",
        category:"Jersey and Shorts",
        price:"$39.00 AUD"
    },
    "qty":"34",
    "size":"XXXL"
    },
]
