import React from 'react'
import gal1 from '../assets/gal1.png'
import gal2 from '../assets/gal2.png'
import gal3 from '../assets/gal3.png'
import gal4 from '../assets/gal4.png'
import '../styles/Gallery.css'
function Gallery() {
  return (
    <>
    {/* //widgth60 */}
           <div className='divder widgth60'>
        <h1 className='heading'>Discover our gallery, showcasing stunning creations</h1>
        
        </div>
        
        <div className='gal-imgcon'>
        <div className='gal-img '>
          <img src={gal1} alt='img'></img>
        </div>
        <div className='gal-img'>
          <img src={gal2} alt='img'></img>
        </div>
        <div className='gal-img'>
          <img src={gal3} alt='img'></img>
        </div>
        <div className='gal-img'>
          <img src={gal4} alt='img'></img>
        </div>
        <div className='gal-img'>
          <img src={gal1} alt='img'></img>
        </div>
        <div className='gal-img'>
          <img src={gal2} alt='img'></img>
        </div>
        <div className='gal-img'>
          <img src={gal3} alt='img'></img>
        </div>
        <div className='gal-img'>
          <img src={gal4} alt='img'></img>
        </div>
        <div className='gal-img'>
          <img src={gal1} alt='img'></img>
        </div>
        <div className='gal-img'>
          <img src={gal2} alt='img'></img>
        </div>
        <div className='gal-img'>
          <img src={gal3} alt='img'></img>
        </div>
        <div className='gal-img'>
          <img src={gal4} alt='img'></img>
        </div>
        <div className='gal-img'>
          <img src={gal1} alt='img'></img>
        </div>
        <div className='gal-img'>
          <img src={gal2} alt='img'></img>
        </div>
        <div className='gal-img'>
          <img src={gal3} alt='img'></img>
        </div>
        <div className='gal-img'>
          <img src={gal4} alt='img'></img>
        </div>
        </div>

    </>
  )
}

export default Gallery