
import React from 'react'
import './input.css'
function Input({type,placeholder}) {
  return (
    <>
    <input className='contact-input' type={type} placeholder={placeholder}></input>
    </>
  )
}

export default Input